import { FeatureData } from '@innedit/innedit';
import { ReservationType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../../../../components/List/Item/Reservation';
import CMSView from '../../../../../components/View';
import List from '../../../../../containers/Espace/List';
import campagne from '../../../../../params/campagne.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceGestionReservations: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, location, user, params: { espaceId } }) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={location.pathname}
          model={
            new FeatureData<ReservationType>({
              espaceId,
              collectionName: 'reservations',
              params: campagne,
            })
          }
          search={location.search}
          title={t('reservations.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceGestionReservations);
